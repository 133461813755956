body, html {
    margin: 0;
    padding: 0;
    font-family: Arial, sans-serif;
}

.contact-container {
    width: 100%;
    
    margin: 50px auto;
    text-align: center;
    
    padding-bottom: 0[x];
    background-color: #FFF9ED;
    
}

h1 {
    font-size: 2.5em;
    color: #333;
}

p {
    font-size: 1.2em;
    color: #666;
    line-height: 1.5;
}

.links {
    display: flex;                   /* Flexbox layout */
    justify-content: center;         /* Center items horizontally */
    flex-wrap: wrap;                 /* Allow wrapping of items */
    gap: 200px;                       /* Space between the items */
    margin-top: 30px;
}

.link-item {
    display: flex;
    align-items: center;
    gap: 10px;                       /* Space between the icon and the text */
    font-size: 1.2em;
    color: #333;
    text-decoration: none;
    transition: color 0.3s;
    white-space: nowrap;             /* Prevent text from wrapping */
}

.link-item svg {
    width: 16px;                     /* Size of the SVG icons */
    height: 16px;
    fill: currentColor;              /* Icon color matches the text color */
}

.link-item:hover {
    color: #0275d8;                  /* Change color on hover */
}

footer {
    margin-top: 30px;
    color: #666;
}

.heart {
    color: red;                      /* Style for the heart icon/color */
}

#lets-connect {
    text-align: center;
    color: black; /* Default color for the entire text */
}

.my-color {
    color: orange; /* Specific color for the word 'Let’s' */
}
